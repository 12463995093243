import React, { useCallback, useEffect, useState } from 'react';

import { Alert, Button, TextInput } from '@sede-x/shell-ds-react-framework';

import styled from 'styled-components';

import { Col, FormRow } from '../../../../UI/Grid';
import Loader from '../../../../UI/Loader';
import { Modal } from '../../../../UI/Modal';
import { get as GetApi, post as PostAPI } from '../../../../services/COPApi';
import { formattDate } from '../../../../utils/general';
import Info from '../../WstChecklist/WstTasks/Tasks/TaskChecklist/TaskGroup/TaskRow/MoreInfo/Info';
import OpsCommentsList from './OpsCommentsList';

interface ModalProps {
  commentModal: {
    open: boolean;
    comments: string;
    id: number;
    statusValue: number;
    commDate: any;
    historical: boolean;
    name: string;
    description: string;
    modifiedByUserName: string;
    modifiedDateInGMT: string;
    commentDisable: boolean;
  };
  onClose: () => void;
  canListOpps: boolean;
  callBackfetchOppsForOperator: () => void;
}

const StyledBtn = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 2%;
`;

const NoContent = styled.div`
  height: 320px;
  font-weight: 700;
  text-align: left;
  font-size: 18px;
`;

const CommentModal = (props: ModalProps) => {
  const { commentModal, onClose, canListOpps, callBackfetchOppsForOperator } =
    props;

  const [commentsList, setCommentsList] = useState([]);
  const dateVal = commentModal.commDate.format('YYYY-MM-DD');
  const [commentText, setCommentText] = useState('');
  const [loader, setLoader] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);
  const [latestUpdatedBy, setLatestUpdatedBy] = useState<string>('');
  const [latestUpdatedDate, setLatestUpdatedDate] = useState<string>('');
  const [submitBtn, setSubmitBtn] = useState<boolean>(false);

  const fetchAllComments = useCallback(async () => {
    setLoader(true);
    try {
      const response = await GetApi(
        `/api/cop/CommercialOpportunity/GetCommentsByIdAndDate?opportunityId=${commentModal.id}&date=${dateVal}`
      );
      if (response.status === 200) {
        setCommentsList(response.data);
        setLoader(false);
        setErrorMsg(false);
        // first element -> to get latest updated value
        setLatestUpdatedBy(response.data[0].modifiedByUserName);
        setLatestUpdatedDate(response.data[0].modifiedDateTimeInGMT);
      } else {
        setErrorMsg(true);
      }
    } catch (err) {
      setErrorMsg(false);
    }
  }, [commentModal.id, dateVal]);

  const handleCommentsSubmit = () => {
    const reqBody = {
      commercialOpportunityId: commentModal.id,
      comments: commentText,
      status: commentModal.statusValue,
    };
    setSubmitBtn(true);

    PostAPI(`/api/cop/CommercialOpportunity/AddComments`, reqBody)
      .then((response) => {
        if (response.status === 200) {
          fetchAllComments();
          callBackfetchOppsForOperator();
          setCommentText('');
          setErrorMsg(false);
          setSubmitBtn(false);
        } else {
          setErrorMsg(true);
        }
      })
      .catch(() => {
        setCommentText('');
        setErrorMsg(true);
      });
  };

  useEffect(() => {
    if (
      commentModal &&
      commentModal.commDate.isValid() &&
      commentModal.id !== -1
    ) {
      fetchAllComments();
    }
  }, [commentModal, fetchAllComments]);

  let valueToDisplay = 'Not available';

  if (latestUpdatedDate === '') {
    if (
      commentModal.modifiedDateInGMT !== null &&
      commentModal.modifiedDateInGMT !== undefined &&
      commentModal.modifiedDateInGMT !== ''
    ) {
      valueToDisplay = formattDate(commentModal.modifiedDateInGMT, 'lll');
    }
  } else if (
    latestUpdatedDate !== null &&
    latestUpdatedDate !== undefined &&
    latestUpdatedDate !== ''
  ) {
    valueToDisplay = formattDate(latestUpdatedDate, 'lll');
  }

  return (
    <>
      <Modal
        open={commentModal.open}
        footer={null}
        onClose={onClose}
        title='More Info'
        isDraggable
      >
        <>
          <Info label='Flow/Asset' value={commentModal.name} />
          <Info label='Description' value={commentModal.description} />
          <Info
            label='Last Modified By'
            value={
              latestUpdatedBy === ''
                ? commentModal.modifiedByUserName
                : latestUpdatedBy
            }
          />
          <Info label='Last Modified DateTime' value={valueToDisplay} />

          {!commentModal.historical && canListOpps && (
            <>
              <FormRow>
                <Col size={7}>
                  <TextInput
                    defaultValue=''
                    placeholder='Add Comments'
                    value={commentText}
                    onChange={(event) => setCommentText(event.target.value)}
                    data-testid='comments'
                    disabled={!commentModal?.commentDisable}
                  />
                </Col>
                <Col size={3}>
                  <StyledBtn>
                    <Button
                      size='medium'
                      variant='filled'
                      onClick={handleCommentsSubmit}
                      disabled={commentText === '' || submitBtn}
                    >
                      Submit
                    </Button>
                  </StyledBtn>
                </Col>
              </FormRow>
            </>
          )}
          {loader && <Loader />}
          {errorMsg && (
            <Alert state='information'>
              There is some problem in fetching the comments!
            </Alert>
          )}
          {commentsList.length > 0 && !errorMsg && (
            <OpsCommentsList commentsList={commentsList} />
          )}
          {commentsList.length === 0 && !loader && !errorMsg && (
            <NoContent>No Comments History</NoContent>
          )}
        </>
      </Modal>
    </>
  );
};

export default CommentModal;
