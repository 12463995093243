import SuspenseLoadPage from '../components/OtherComponents/SuspenseLoadPage';
import UnauthorizedComp from '../components/OtherComponents/Unauthorized';

const ErrorPage = () => {
  return (
    <SuspenseLoadPage>
      <UnauthorizedComp message='Something went wrong please contact system administrator.' />
    </SuspenseLoadPage>
  );
};

export default ErrorPage;
