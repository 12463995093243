/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getSourceProductionFields } from '../../store/UKOps/FieldNoms';
import { FullStateI } from '../../types/All.type';
import { ISelectOptions } from '../../types/Common.type';

type GetSourceProductionFieldsType = (fieldMapping?: boolean) => {
  loadingSourceProductionFields: boolean;
  sourceProductionFields: ISelectOptions[];
};

const useGetSourceProductionFields: GetSourceProductionFieldsType = (
  fieldMapping = false
) => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(
    (state: FullStateI) => state.FieldNoms.sourceProductionField
  );

  useEffect(() => {
    if (data.length === 0 && !loading) {
      dispatch(getSourceProductionFields());
    }
  }, [data]);

  return {
    loadingSourceProductionFields: loading,
    sourceProductionFields: fieldMapping
      ? data
      : data.filter((field) => field.isVisible),
  };
};

export default useGetSourceProductionFields;
