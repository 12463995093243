/* eslint-disable no-param-reassign, @typescript-eslint/no-unused-vars */
import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  current,
} from '@reduxjs/toolkit';

import { GetAllUnitsByFormulaTypeAPI } from '../../constants/ConversionCalculator';
import {
  FIELD_NOMS_DEADLINE_NOTIFICATION,
  GET_PRODUCTION_FIELDS,
  GET_SOURCE_PRODUCTION_FIELDS,
  GET_SUB_TERMINALS,
  GET_TERMINALS,
  POST_UPLOAD_TO_ALIGNE,
} from '../../constants/UKNomination/FieldNomination';
import { get, post } from '../../services/COPApi';
import { ISelectOptions } from '../../types/Common.type';
import {
  IFieldNomination,
  IUploadToAlignePayload,
} from '../../types/UKNomination/FieldNomination.type';
import {
  EFormulaType,
  getFormulaForDisplay,
} from '../../utils/conversion_calculator';

interface INotifications {
  note: string;
  timer: string;
}

interface ISelectField {
  loading: boolean;
  data: ISelectOptions[];
}

export interface IFormula {
  id: number;
  name: string;
  formula: string;
}

const selectInitialData = {
  loading: false,
  data: [],
};

export interface IFieldNomsState {
  productionField: ISelectField;
  sourceProductionField: ISelectField;
  terminal: ISelectField;
  subTerminal: ISelectField;
  uploadToAligne: {
    loading: boolean;
    uploadStatus: 'Success' | 'Failed' | null;
  };
  formulaData: {
    loading: boolean;
    data: IFormula[];
  };
  notifications: {
    loading: boolean;
    count: number;
    data: INotifications[];
  };
}

const initialState: IFieldNomsState = {
  productionField: selectInitialData,
  sourceProductionField: selectInitialData,
  terminal: selectInitialData,
  subTerminal: selectInitialData,
  uploadToAligne: {
    loading: false,
    uploadStatus: null,
  },
  formulaData: {
    loading: false,
    data: [],
  },
  notifications: {
    loading: false,
    count: 0,
    data: [],
  },
};

export const getProductionFields = createAsyncThunk(
  'fieldNoms/getProductionFields',
  async () =>
    get(GET_PRODUCTION_FIELDS).then((resp) => {
      return resp.data;
    })
);

export const getSourceProductionFields = createAsyncThunk(
  'fieldNoms/getSourceProductionFields',
  async () =>
    get(GET_SOURCE_PRODUCTION_FIELDS).then((resp) => {
      return resp.data;
    })
);

export const getTerminals = createAsyncThunk(
  'fieldNoms/getTerminals',
  async () =>
    get(GET_TERMINALS).then((resp) => {
      return resp.data;
    })
);

export const getSubTerminals = createAsyncThunk(
  'fieldNoms/getSubTerminals',
  async () =>
    get(GET_SUB_TERMINALS).then((resp) => {
      return resp.data;
    })
);

export const getFormula = createAsyncThunk('fieldNoms/getFormula', async () =>
  get(GetAllUnitsByFormulaTypeAPI(EFormulaType.NOMINATION_CALCULATOR)).then(
    (resp) => {
      const responseData = resp.data || [];
      return responseData.map((item) => ({
        id: item.id,
        name: item.formulaName,
        formula: getFormulaForDisplay(item),
      }));
    }
  )
);

export const postUploadToAligne = createAsyncThunk(
  'fieldNoms/postUploadToAligne',
  async (payload: IUploadToAlignePayload[]) => {
    return post(POST_UPLOAD_TO_ALIGNE, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        throw error;
      });
  }
);

export const getDeadlineNotifications = createAsyncThunk(
  'fieldNoms/getDeadlineNotifications',
  async (date: string) => {
    return get(`${FIELD_NOMS_DEADLINE_NOTIFICATION}/${date}`)
      .then((resp) => {
        return resp.data || [];
      })
      .catch((error) => {
        throw error;
      });
  }
);

const fieldNomsSlice = createSlice({
  name: 'fieldNoms',
  initialState,
  reducers: {
    clearUploadResponse: (state) => {
      state.uploadToAligne.loading = false;
      state.uploadToAligne.uploadStatus = null;
    },
    updateProdFields: (
      state,
      action: PayloadAction<{
        prodFieldId: number;
        sourceProdFieldId: number;
        status: boolean;
      }>
    ) => {
      const { prodFieldId, sourceProdFieldId, status } = action.payload;

      const updateFieldVisibility = (
        fields: ISelectOptions[],
        fieldId: number
      ) =>
        fields.map((field) =>
          field.id === fieldId ? { ...field, isVisible: status } : field
        );

      state.productionField.data = updateFieldVisibility(
        state.productionField.data,
        prodFieldId
      );
      state.sourceProductionField.data = updateFieldVisibility(
        state.sourceProductionField.data,
        sourceProdFieldId
      );
    },
  },
  extraReducers: (builder) => {
    // productionField
    builder
      .addCase(getProductionFields.pending, (state: IFieldNomsState) => {
        state.productionField.loading = true;
        state.productionField.data = [];
      })
      .addCase(
        getProductionFields.fulfilled,
        (
          state: IFieldNomsState,
          { payload }: PayloadAction<ISelectOptions[]>
        ) => {
          state.productionField.loading = false;
          state.productionField.data = payload;
        }
      )
      .addCase(getProductionFields.rejected, (state: IFieldNomsState) => {
        state.productionField.loading = false;
        state.productionField.data = [];
      });

    // sourceProductionField
    builder
      .addCase(getSourceProductionFields.pending, (state: IFieldNomsState) => {
        state.sourceProductionField.loading = true;
        state.sourceProductionField.data = [];
      })
      .addCase(
        getSourceProductionFields.fulfilled,
        (
          state: IFieldNomsState,
          { payload }: PayloadAction<ISelectOptions[]>
        ) => {
          state.sourceProductionField.loading = false;
          state.sourceProductionField.data = payload;
        }
      )
      .addCase(getSourceProductionFields.rejected, (state: IFieldNomsState) => {
        state.sourceProductionField.loading = false;
        state.sourceProductionField.data = [];
      });

    // terminal
    builder
      .addCase(getTerminals.pending, (state: IFieldNomsState) => {
        state.terminal.loading = true;
        state.terminal.data = [];
      })
      .addCase(
        getTerminals.fulfilled,
        (
          state: IFieldNomsState,
          { payload }: PayloadAction<ISelectOptions[]>
        ) => {
          state.terminal.loading = false;
          state.terminal.data = payload;
        }
      )
      .addCase(getTerminals.rejected, (state: IFieldNomsState) => {
        state.terminal.loading = false;
        state.terminal.data = [];
      });

    // subTerminal
    builder
      .addCase(getSubTerminals.pending, (state: IFieldNomsState) => {
        state.subTerminal.loading = true;
        state.subTerminal.data = [];
      })
      .addCase(
        getSubTerminals.fulfilled,
        (
          state: IFieldNomsState,
          { payload }: PayloadAction<ISelectOptions[]>
        ) => {
          state.subTerminal.loading = false;
          state.subTerminal.data = payload;
        }
      )
      .addCase(getSubTerminals.rejected, (state: IFieldNomsState) => {
        state.subTerminal.loading = false;
        state.subTerminal.data = [];
      });

    // getFormula
    builder
      .addCase(getFormula.pending, (state: IFieldNomsState) => {
        state.formulaData.loading = true;
        state.formulaData.data = [];
      })
      .addCase(
        getFormula.fulfilled,
        (state: IFieldNomsState, { payload }: PayloadAction<IFormula[]>) => {
          state.formulaData.loading = false;
          state.formulaData.data = payload;
        }
      )
      .addCase(getFormula.rejected, (state: IFieldNomsState) => {
        state.formulaData.loading = false;
        state.formulaData.data = [];
      });

    // postUploadToAligne
    builder
      .addCase(postUploadToAligne.pending, (state: IFieldNomsState) => {
        state.uploadToAligne.loading = true;
        state.uploadToAligne.uploadStatus = null;
      })
      .addCase(
        postUploadToAligne.fulfilled,
        (
          state: IFieldNomsState,
          { payload }: PayloadAction<IFieldNomination[]>
        ) => {
          state.uploadToAligne.loading = false;
          state.uploadToAligne.uploadStatus = 'Success';
        }
      )
      .addCase(postUploadToAligne.rejected, (state: IFieldNomsState) => {
        state.uploadToAligne.loading = false;
        state.uploadToAligne.uploadStatus = 'Failed';
      });

    // get field noms deadline notifications
    builder
      .addCase(getDeadlineNotifications.pending, (state: IFieldNomsState) => {
        state.notifications.loading = true;
        state.notifications.data = [];
        state.notifications.count = 0;
      })
      .addCase(
        getDeadlineNotifications.fulfilled,
        (
          state: IFieldNomsState,
          { payload }: PayloadAction<INotifications[]>
        ) => {
          state.notifications.loading = false;
          state.notifications.data = payload;
          state.notifications.count = payload.length;
        }
      )
      .addCase(getDeadlineNotifications.rejected, (state: IFieldNomsState) => {
        state.notifications.loading = false;
        state.notifications.data = [];
        state.notifications.count = 0;
      });
  },
});

export const FieldNomsActions = fieldNomsSlice.actions;
export default fieldNomsSlice.reducer;
