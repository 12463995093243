/* eslint-disable no-param-reassign */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { get as GetApi, post as PostApi } from '../../services/COPApi';
import {
  AddOrUpdateTradeNoteLogAction,
  AddTradeControlLogsPayloadI,
  DailyNoteItem,
  TradeControlI,
} from '../../types/OpsChecklist.type';

interface FetchAllDataI {
  taskDate: string;
  countryId: string;
}

const initialState: TradeControlI = {
  isFetching: false,
  error: null,
  list: [],
  isUpdating: false,
  errorWhileUpdating: null,
  loadingOperators: false,
  operatorOptions: [],
  confirmationsoperatorOptions: [],
  operatorDataError: null,
};

export const fetchAllDailyNotes = createAsyncThunk(
  'TradeControl/GetAllDailyNote',
  async (data: FetchAllDataI) => {
    return GetApi(
      `/api/cop/TradeControl/GetAllDailyNote?logDate=${data.taskDate}&countryId=${data.countryId}`
    ).then((resp) => {
      return resp.data;
    });
  }
);

export const fetchAllOperators = createAsyncThunk(
  'ptoLogs/fetchAllOperators',
  async () =>
    GetApi('/api/User/GetAllUser?team=3').then((resp) => {
      return resp.data;
    })
);

export const fetchAllOperatorsConfirmations = createAsyncThunk(
  'opsChecklist/Confirmations',
  async () =>
    GetApi('/api/User/GetAllUser?team=4').then((resp) => {
      return resp.data;
    })
);

export const addOrUpdateTradeNoteLog = createAsyncThunk(
  'TradeControl/AddOrUpdateTradeNoteLog',
  async (data: AddTradeControlLogsPayloadI) =>
    PostApi('/api/cop/TradeControl/AddOrUpdateTradeNoteLog', data).then(
      (resp) => {
        return resp.data;
      }
    )
);

const TradeControlSlice = createSlice({
  name: 'shiftName',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDailyNotes.pending, (state) => {
        state.isFetching = true;
        state.error = null;
        state.list = [];
      })
      .addCase(
        fetchAllDailyNotes.fulfilled,
        (state, action: PayloadAction<DailyNoteItem[]>) => {
          state.isFetching = false;
          state.error = null;
          state.list = action.payload;
        }
      )
      .addCase(fetchAllDailyNotes.rejected, (state: any) => {
        state.isFetching = false;
        state.error = 'Error';
        state.list = [];
      });

    builder
      .addCase(addOrUpdateTradeNoteLog.pending, (state: any) => {
        state.isUpdating = true;
        state.errorWhileUpdating = null;
      })
      .addCase(
        addOrUpdateTradeNoteLog.fulfilled,
        (state, action: AddOrUpdateTradeNoteLogAction) => {
          state.isUpdating = false;
          state.errorWhileUpdating = null;
          const { countryId, note } = action?.meta?.arg ?? {};
          const updatedList = state.list.map((item) =>
            item.countryId === countryId
              ? { ...item, id: action.payload.id, dailyNote: note }
              : item
          );

          state.list = updatedList;
        }
      )
      .addCase(addOrUpdateTradeNoteLog.rejected, (state: any) => {
        state.isUpdating = false;
        state.errorWhileUpdating = 'Error';
      });

    builder
      .addCase(fetchAllOperators.pending, (state) => {
        state.loadingOperators = true;
        state.error = null;
      })
      .addCase(fetchAllOperators.fulfilled, (state, action) => {
        state.operatorOptions = action.payload;
        state.loadingOperators = false;
      })
      .addCase(fetchAllOperators.rejected, (state) => {
        state.loadingOperators = false;
        state.operatorOptions = [];
        state.error = 'Error';
      });

    builder
      .addCase(fetchAllOperatorsConfirmations.pending, (state) => {
        state.loadingOperators = true;
        state.error = null;
      })
      .addCase(fetchAllOperatorsConfirmations.fulfilled, (state, action) => {
        state.confirmationsoperatorOptions = action.payload;
        state.loadingOperators = false;
      })
      .addCase(fetchAllOperatorsConfirmations.rejected, (state) => {
        state.loadingOperators = false;
        state.confirmationsoperatorOptions = [];
        state.error = 'Error';
      });
  },
});

export const TradeControlActions = TradeControlSlice.actions;
export default TradeControlSlice.reducer;
