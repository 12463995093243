/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getProductionFields } from '../../store/UKOps/FieldNoms';
import { FullStateI } from '../../types/All.type';
import { ISelectOptions } from '../../types/Common.type';

type GetProductionFieldsType = (
  getCall: boolean,
  fieldMapping?: boolean
) => {
  loadingProductionFields: boolean;
  productionFields: ISelectOptions[];
};

const useGetProductionFields: GetProductionFieldsType = (
  getCall = false,
  fieldMapping = false
) => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(
    (state: FullStateI) => state.FieldNoms.productionField
  );

  useEffect(() => {
    if (data.length === 0 && !loading && getCall) {
      dispatch(getProductionFields());
    }
  }, [data, getCall]);

  return {
    loadingProductionFields: loading,
    productionFields: fieldMapping
      ? data
      : data.filter((field) => field.isVisible),
  };
};

export default useGetProductionFields;
